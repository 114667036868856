import { styled } from "linaria/react";
import { UnitlessScale } from "src/theme/scale";
import { colors, FontScale } from "theme";

type Props = { color?: keyof typeof colors };
const Body = styled.p<Props>`
  color: ${({ color = "olympicSkaterSpandex" }) => colors[color]};
  font-size: ${FontScale(4.25)};
  line-height: ${UnitlessScale(4.75)};
  word-break: break-word;
  a {
    cursor: pointer;
    transition: color 200ms;
    color: ${colors.apple};
    &:hover {
      color: ${colors.ivy};
    }
  }
`;

export default Body;
